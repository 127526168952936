Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#notifications',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        notifications: [],

        filters: {
            unseen: function(notification) {
                return ! notification.seen;
            }
        }
    },

    computed: {
        new: function() {
            return this.notifications.filter(this.filters.unseen);
        }
    },

    ready: function() {
        this.$set('title', document.title);

        this.getNotifications();
        var self = this;
        window.setInterval(function() {
            self.getNotifications();
        }, 30000); // We'll check for new notifications every 30 seconds
    },

    methods: {
        getNotifications: function() {
            this.$http.get(this.api_url+'/notifications', function(response) {
                this.notifications = response.notifications;
                if(this.new.length) {
                    document.title = '('+this.new.length+') '+this.title;
                }
            }).error(function (data, status, response) {
                //console.log(response);
            });
        },

        seeNotifications: function() {
            if(this.new.length) {
                this.$http.put(this.api_url+'/notifications', function(response) {
                    //console.log(response);
                    this.notifications.forEach(function(notification) {
                        notification.seen = true;
                    });
                    document.title = this.title;
                }).error(function (data, status, response) {
                    //console.log(response);
                });
            }
        },
    }
});
