Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#account',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        view: 'list',
        clients: [],
    },

    ready: function() {
        this.getClientList();
    },

    methods: {

        getClientList: function() {
            this.$set('loading.clients', true);
            this.$http.get(this.api_url+'/clients', function(response) {
                this.$set('clients', response.clients);
                this.$set('loading.clients', false);
            }).error(function (data, status, response) {
                //console.log(response);
            });
        },

    },
});
