Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.filter('time', function(minutes) {
    if(! minutes) return '-';

    var hours = Math.floor(minutes / 60);
    var mins = minutes % 60;

    if(mins === 0) return hours+'h';

    return hours+'h '+mins+'m';
});

Vue.filter('toNow', function(date) {
    if(! date) return '-';

    dateparts = date.split('/');
    //console.log(dateparts);
    return moment([dateparts[2], dateparts[1], dateparts[0]]).toNow();

});

Vue.filter('fromNow', function(date) {
    if(! date) return '-';

    dateparts = date.split('/');
    //console.log(dateparts);
    return moment([dateparts[2], dateparts[1], dateparts[0]]).fromNow();

});
